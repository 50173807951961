import React,{useState,useEffect,useRef} from 'react';
import {  useSelector } from 'react-redux';

import moment from 'moment'
import axios from 'axios'


import { 
    Layout,
    Row,Col,Table,
    PageHeader,
    Button,
    DatePicker,
    Select 
   } from 'antd';

  

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

const { Content} = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const start_dt = moment().subtract('month', 3).format(dateFormat)

const TaskList =()=>{

    const [startdt,setStartDt]=useState(start_dt)
    const [enddt,setEndDt]=useState(moment().format('YYYY-MM-DD'))  
    const [listdata,setListData] = useState([]);
    const [pagenation,setpagenation] = useState({})
    const [loading,setLoading] = useState(false);
    const [pageData,setPageData] = useState({page:1,page_size:10})

    window.scrollTo(0, 0);


    const user = useSelector(state => state.user.user);

    const stadtDtHandler=(date, dateString)=>{

        console.log(moment(date).format('YYYY-MM-DD'))
        console.log(dateString)

        
        setStartDt(moment(date).format('YYYY-MM-DD'))


    }

    const endDtHandler=(date, dateString)=>{
        setEndDt(moment(date).format('YYYY-MM-DD'))

    }


    useEffect(()=>{

        getData({pageSize:10,page:1,startdt,enddt})
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      const getData= async (params)=>{

        params.customer_id = user.customer_id;

        console.log("params",params)

      
        const {data} = await axios.post('api/task/01_getTaskList',params)
         console.log('data==>',data);
         const _pagenation = {...pagenation }

         _pagenation.total = data.total_record;
         console.log("data_list",data.record_list)

         setListData(data.record_list)
         setLoading(false)
         setpagenation(_pagenation)

       

    }

    const handleTableChange=async (pagination, filters, sorter)=>{

        console.log('pagination',pagination)
        console.log('filters',filters)
        console.log('sorter',sorter)

        const pager = { pagenation };
        pager.current = pagenation.current;
        setpagenation(pager);

        setPageData({page:pagenation.current,page_size:pagenation.pageSize})

        await getData({
          pageSize: pagenation.pageSize,
          page: pagenation.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          startdt:startdt,
          enddt:enddt,
          ...filters,
        });

    }

    const searchData= ()=>{

        const param={startdt,enddt,pageSize:10,page:1}
        console.log(param)

        getData(param)

    }

    const dateYYYYMMDD = (text) =>{
      
        return text ? moment(text).format('YYYY-MM-DD'):'-'
        
  
      }

    const columns=[

        {
            title: 'NUM',
            key: 'index',
            render:(text, record, index) => {
               return index+((pageData.page-1)*pageData.page_size)+1
            }
          },
          {
            title: 'LARGE CATEGORY',
            dataIndex: 's1_name',
            key: 's1_name',
          
            
  
          },
          {
            title: 'DIVISION',
            dataIndex: 's2_name',
            key: 's2_name',
            responsive: ['xxs'],
          
            
  
          },
          {
            title: 'SUBDIVISION',
            dataIndex: 's3_name',
            key: 's3_name',
            responsive: ['sm'],
           
            
  
          },
          {
            title: 'TASK',
            dataIndex: 's4_name',
            key: 's4_name',
     
          },
          {
            title: 'COMMENT',
            dataIndex: 'comment',
            key: 'comment',
            responsive: ['lg'],
            width:'500px',
            render:(text)=>{
                return(
                    <div>{text}</div>
                )
            }
            
  
          },
         
          {
                title: 'Reg. Date',
                dataIndex: 'reg_dt',
                key: 'reg_dt',
                responsive: ['lg'],
                render: (text) =>{
                  return dateYYYYMMDD(text)
                },
              },
    

    ]

    return(

        <AdminLayout menukey='3'>
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>
       
                 <PageHeader className="site-page-header mb-5" title="TASK ACTION LIST" subTitle=''/>
                
                <div className="">

                    <Row gutter={[{ xs: 4, sm: 16, md: 24, lg: 32 }, 24]}>
             
                        <Col span={23} offset={1} className="d-flex align-content-center">
                            <span className="searchTitle">Duriation</span>
                            <DatePicker defaultValue={moment(startdt,dateFormat)} onChange={stadtDtHandler} className="mr-4"/>
                            <DatePicker defaultValue={moment(enddt,dateFormat)}   onChange={endDtHandler} />
                            
                            <Button type="primary" style={{marginLeft:'10px'}} onClick={searchData}>Search</Button>
         
                        </Col>
                    
                    
                    </Row>
                    <Row gutter={[{ xs: 4, sm: 16, md: 24, lg: 32 }, 24]}>
                    <Col span={24}>
                        <Table columns={columns}
                            rowKey={record => record.id}
                            dataSource={listdata} 
                            pagination={pagenation}
                            loading={loading}
                            onChange={handleTableChange}
                            size="middle"
                            style={{ marginTop: 20 }}
            
                        />
                    </Col>
                    </Row>
                 
                 </div>
                 </Content>
            </div>

            
      
        </AdminLayout>

    )
}


export default TaskList