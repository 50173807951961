import React, { useEffect} from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { history } from './helper'
import { alertAction } from './actions'
import { PrivateRoute}  from './component/PrivateRoute';
//import Login from './container/Login'
//import TicketList from './container/TicketList';
//import TickeDetail from './container/TicketDetail';
import { Login, TicketReg, TicketList, TicketDetail, TicketUpdate,TaskList} from './container'



import './App.css'


const App =()=>{

    const alert = useSelector( state => state.alert);

    console.log("alert",alert)

    const dispatch = useDispatch();

     useEffect(() => {

         history.listen((location, action) => {

             dispatch(alertAction.clear());
         });
       
        dispatch(alertAction.clear());

    },[dispatch]); 



    return (
        <div>
           
           <Router history={ history }>
               <Switch>
                    <PrivateRoute exact path="/" component= {TicketList} /> 

                   <Route path="/ticketList" component= {TicketList} />
                   <Route path="/ticketDetail" component= {TicketDetail} />
                   <Route path="/ticketReg" component= {TicketReg} />
                   <Route path="/ticketUpdate/:id" component= {TicketUpdate} />
                  {/* <Route path="/taskList" component= {TaskList} /> */}
                   <Route path="/login" component= {Login} />

                   <Redirect from="*" to="/" /> 

               </Switch>
           </Router>

        </div>
    )


}

export default App;
