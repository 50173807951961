import React,{useState,useEffect,useRef} from 'react';
import {  useSelector } from 'react-redux';

import moment from 'moment'
import axios from 'axios'


import { 
    Layout,
    Row,Col,Table,
    PageHeader,
    Button,
    DatePicker,
    Select 
   } from 'antd';

  

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

const { Content} = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const start_dt = moment().subtract('month', 6).format(dateFormat)



const TicketList =()=> {

    const [startdt,setStartDt]=useState(start_dt)
    const [enddt,setEndDt]=useState(moment().add('hours',10).format('YYYY-MM-DD HH:mm:ss'))  //db에 데이터가 utc+0로 저장되기 때문에 미국 동부 표준시 보정
    const [listdata,setListData] = useState([]);
    const [pagenation,setpagenation] = useState({})
    const [loading,setLoading] = useState(false);
    const [pageData,setPageData] = useState({page:1,page_size:10})

    window.scrollTo(0, 0);

    const issueType = useRef('');
    const status = useRef('');

    const user = useSelector(state => state.user.user);

    useEffect(()=>{

        getData({pageSize:10,page:1,startdt,enddt})
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    const getData= async (params)=>{

        params.customer_id = user.customer_id;

      

        const {data} = await axios.post('/api/ticket/01_list',params)
        console.log('data==>',data);
        const _pagenation = {...pagenation }

        _pagenation.total = data.total_record;
        console.log("data_list",data.record_list)

        setListData(data.record_list)
        setLoading(false)
        setpagenation(_pagenation)

       

    }


    const dtHandelr=(dates, dateStrings)=>{

        setStartDt(dateStrings[0])
        setEndDt(dateStrings[1])

    }

    const typeHandler =(value)=>{

        console.log(value)
        issueType.current = value

    }

    const statusHandler =(value)=>{

        console.log(value)
        status.current = value

    }

    const searchData = async ()=>{
       
        console.log(issueType.current,status.current)

        const params={
          enddt:enddt,
          startdt:startdt,
          issueType :issueType.current,
          status : status.current,
        }

        console.log('search param',params)

        await getData(params)

    }

   

    const handleTableChange=async (pagination, filters, sorter)=>{

        console.log('pagination',pagination)
        console.log('filters',filters)
        console.log('sorter',sorter)

        const pager = { pagenation };
        pager.current = pagenation.current;
        setpagenation(pager);

        setPageData({page:pagenation.current,page_size:pagenation.pageSize})

        await getData({
          pageSize: pagenation.pageSize,
          page: pagenation.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          start_dt:startdt,
          end_dt:enddt,
          issueType :issueType.current,
          status : status.current,
          ...filters,
        });

    }

    const dateYYYYMMDD = (text) =>{
      
      return text ? moment(text).format('YYYY-MM-DD'):'-'
      

    }

    const gotoDetail=(e,record) =>{

      console.log(record);

       history.push('/ticketDetail', { id: record.id });

    }

    const columns = [
        {
          title: 'Num',
          key: 'index',
          render:(text, record, index) => {
             return index+((pageData.page-1)*pageData.page_size)+1
          }
        },
        {
          title: 'Ticket Number',
          dataIndex: 'ticket_id',
          key: 'ticket_id',
          responsive: ['lg'],
          

        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          width: '40%',
          render:(text, record,index) => {

            return(
              <span onClick={ (e)=>gotoDetail(e,record)} style={{color:'blue', cursor:'pointer',textDecoration:'underline'}}>{text}</span>
            )

          }

        },
        {
          title: 'Location',
          dataIndex: 'location',
          key: 'location',
          responsive: ['lg'],

        },
        {
          title: 'Issuer',
          dataIndex: 'issuer',
          key: 'issuer',
          responsive: ['lg'],

        },
        {
            title: 'Issue',
            dataIndex: 'issuetype',
            key: 'issuetype',
            responsive: ['lg'],

        },
        {
            title: 'Service',
            dataIndex: 'servicetype',
            key: 'servicetype',
            responsive: ['lg'],

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',

        },
        
        {
              title: 'Reg. Date',
              dataIndex: 'createdAt',
              key: 'createdAt',
              responsive: ['lg'],
              render: (text) =>{
                return dateYYYYMMDD(text)
              },
            },
  
         
        ];


    return(
      
        <AdminLayout menukey='1'>
            
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>
                
                <PageHeader className="site-page-header mb-5" title="TICKET LIST" subTitle=''/>
                
                <div className="pc_container">

                <Row gutter={[{ xs: 4, sm: 16, md: 24, lg: 32 }, 24]}>
                  
                  <Col md={10} sm={24} xs={24}>
                     <span className="searchTitle">DURIATION</span>
                    <RangePicker
                            defaultValue={[moment(startdt, dateFormat), moment(enddt, dateFormat)]}
                            onChange={dtHandelr}
                            />
                    
                  </Col>
                  
                  <Col md={6} sm={5} xs={7}>
                  <span className="searchTitle" >ISSUE TYPE</span>
                    <Select  onChange={typeHandler} placeholder="Select Type">
                        <Option value="">All</Option>
                        <Option value="Internet">Internet</Option>
                        <Option value="Server">Server</Option>
                        <Option value="Stroage">Stroage</Option>
                        <Option value="Printer">Printer</Option>
                        <Option value="E-mail">E-mail</Option>
                        <Option value="PC">PC</Option>
                        <Option value="CCTV">CCTV</Option>
                        <Option value="ETC">ETC</Option>
                   </Select>
                

                  
                  </Col>
                  <Col md={6} sm={5} xs={7}>
                  <span className="searchTitle">STATUS</span>
                  <Select style={{ width: 130 }} onChange={statusHandler} placeholder="Select Status">
                        <Option value="">All</Option>
                        <Option value="Received">Received</Option>
                        <Option value="Processing">Processing</Option>
                        <Option value="Pending">Pending</Option>
                        <Option value="Completed">Completed</Option>
                   </Select>
                  
                  </Col>
                  <Col md={2} sm={4} xs={7}>
                    <Button type="primary" style={{marginLeft:'10px'}} onClick={searchData}>Search</Button>
                  </Col>
                 
                </Row>
                

               
                <Row>
                    <Col span={24}>
                    <Table columns={columns}
                        rowKey={record => record.id}
                        dataSource={listdata} 
                        pagination={pagenation}
                        loading={loading}
                        onChange={handleTableChange}
                        size="middle"
                        style={{ marginTop: 20 }}
        
                    />
                    </Col>
                    
                </Row>

                </div>

                {/* Mobile screens */}

                <div className="mobile_container">

                <Row gutter={[{ xs: 4, sm: 16, md: 24, lg: 32 }, 24]} justify="center" align="middle">

                  <Col span={23} offset={1}>

                  <div className="searchTitle">Duriation</div>
                    <RangePicker
                            defaultValue={[moment(startdt, dateFormat), moment(enddt, dateFormat)]}
                            onChange={dtHandelr}
                            style={{width:'100%'}}
                    /> 
                  
                  </Col>

                
                </Row>
                <Row gutter={[{ xs: 4, sm: 16, md: 24, lg: 32 }, 24]} justify="center" align="middle">

                  <Col span={23} offset={1}>

                  <div className="searchTitle">ISSUE TYPE</div>
                    <Select  style={{ width: '100%' }} onChange={typeHandler} placeholder="Select Type">
                          <Option value="">All</Option>
                          <Option value="Internet">Internet</Option>
                          <Option value="Server">Server</Option>
                          <Option value="Stroage">Stroage</Option>
                          <Option value="Printer">Printer</Option>
                          <Option value="E-mail">E-mail</Option>
                          <Option value="PC">PC</Option>
                          <Option value="CCTV">CCTV</Option>
                          <Option value="ETC">ETC</Option>
                    </Select>
                  
                  </Col>

                
                </Row>
                <Row gutter={[{ xs: 4, sm: 16, md: 24, lg: 32 }, 24]} justify="center" align="middle">

                  <Col span={23} offset={1}>

                  <div className="searchTitle">STATUS</div>
                    <Select style={{ width: '100%' }} onChange={statusHandler} placeholder="Select Status">
                        <Option value="">All</Option>
                        <Option value="Received">Received</Option>
                        <Option value="Processing">Processing</Option>
                        <Option value="Pending">Pending</Option>
                        <Option value="Completed">Completed</Option>
                    </Select>
                  
                  </Col>

                
                </Row>
                <Row gutter={[4, 24]} justify="center" align="middle">

                  <Col span={12}>
                  <Button type="primary" block onClick={searchData}>Search</Button>
                  </Col>

                
                </Row>

                <Row>
                    <Col span={24}>
                    <Table columns={columns}
                        rowKey={record => record.id}
                        dataSource={listdata} 
                        pagination={pagenation}
                        loading={loading}
                        onChange={handleTableChange}
                        size="middle"
                        style={{ marginTop: 20 }}
        
                    />
                    </Col>
                    
                </Row>
                 
               </div>

               {/* end of moblie*/}
             
               


               </Content>
            
            </div>

            
            </AdminLayout>
    )
}


export default TicketList;