import React, { useState, useRef } from 'react'
import {  useSelector } from 'react-redux';
import axios from 'axios'
import { Link } from 'react-router-dom';
import {Input} from 'antd'
import moment from 'moment';

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

import { 
    Layout,
    Row,Col,
    PageHeader,
    Card  
   } from 'antd';

import { DeleteOutlined, LeftSquareOutlined,AppstoreOutlined} from '@ant-design/icons'; 



   
const { Content} = Layout;

const TicketDetail = ({location})=>{

    window.scrollTo(0, 0);
    
    const [ticketInfo, setTicketInfo] = useState({})
  
    const [jobList, setJobList] = useState([])
    const[filelist,setFileList] = useState([]);
     
    const user = useSelector(state => state.user.user);

    const [lcoment, setLcoment] = useState('')

    const comment = useRef('')

    React.useEffect(()=>{

        getData()        


    },[])


    const getData =()=>{

        const param={id:location.state.id}

        axios.post('api/ticket/05_detail', param).then(res=>{
            
            console.log(res.data.result)

            const data = res.data.result;

            data.createdAt = moment(res.data.result.createdAt).format('YYYY-MM-DD HH:mm:ss')

            if(res.data.result.complete_dt){

                data.complete_dt = moment(res.data.result.complete_dt).format('YYYY-MM-DD HH:mm:ss')
            }

            const file_list = res.data.result.fileUrl ? res.data.result.fileUrl.split(',') : []
            
            setFileList(file_list)

            const fileter_list = res.data.result.TicketLogMasters.filter(item=>(item.status === 1))


            setTicketInfo(data)
            setJobList(fileter_list)

        })

    }

    const delTicket= async ()=>{

        const {data} = await axios.post('api/ticket/04_delete',{id:ticketInfo.id})

        console.log(data);

        history.push('/ticketList')

    }

    const saveJobLog =async ()=>{

        console.log('saveJobLog comment',lcoment)

        const addBr = lcoment.replace(/(\n|\r\n)/g, '<br>');

        console.log(addBr)

        const param={ticketMasterId:ticketInfo.id,content:addBr,writer:user.username}

         const {data} = await axios.post('api/ticketlog/01_write', param)

         console.log(data);
        setLcoment('')
       
         getData()  
       
      
    }

    const removeBr=(text)=>{

        const _txt  = text.replace(/(<br>|<br\/>|<br \/>)/g, '\r\n');

        return _txt
    }

    const deleteLog= async (item)=>{

        console.log(item);

        await axios.post("api/ticketlog/03_delete",{id:item})

        getData()  
    }

    return(
        <AdminLayout menukey='1'>
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>

                <div className="pc_container">

                <PageHeader className="site-page-header_detail" title="Ticket Detail" subTitle='This is your request'/>
                    
                    <Row>
                        <Col lg={ {span:16, offset:1} }  md={{span:23, offset:1}} sm={{span:23, offset:1}} xs={{span:23, offset:1}} >

                           

                            <Row>
                                <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper">
                                            <div className="itemLabel">Ticket No.</div>
                                            <div className="form-control" style={{flex:'1'}}>{ticketInfo.ticket_id}</div>
                                        </div> 
                                </Col>
                            </Row>

                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper">
                                            <div className="itemLabel">Time received</div>
                                            <div className="form-control" style={{flex:'1'}}>{ticketInfo.createdAt}</div>
                                        </div> 
                                </Col>
                            </Row>

                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper">
                                            <div className="itemLabel">Title</div>
                                            <div className="form-control" style={{flex:'1'}}>{ticketInfo.title}</div>
                                        </div> 
                                </Col>
                            </Row>
                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper">
                                            <div className="itemLabel">Issuer</div>
                                            <div className="form-control" style={{flex:'1'}}>{ticketInfo.issuer}</div>
                                        </div> 
                                </Col>
                            </Row>
                            {/* 
                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper">
                                            <div className="itemLabel">Location</div>
                                            <div className="form-control" style={{flex:'1'}}>
                                            { ticketInfo.location1 }
                                            {
                                                ticketInfo.location2 &&

                                                <span>/ {ticketInfo.location2}</span>

                                            }

                                            </div>
                                        </div> 
                                </Col>
                            </Row>
                             */}
                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper">
                                            <div className="itemLabel">Issue Type</div>
                                            <div className="form-control" style={{flex:'1'}}>{ticketInfo.issuetype}</div>
                                        </div> 
                                </Col>
                            </Row>
                            {/*                             
                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper">
                                            <div className="itemLabel">Service Type</div>
                                            <div className="form-control" style={{flex:'1'}}>{ticketInfo.servicetype}</div>
                                        </div> 
                                </Col>
                            </Row>
                            */}

                            {filelist.length >0 &&
                            <Row>
                            
                            <Col span={10}>

                                     <div className="itemWrapper">
                                        <div className="itemLabel" >Attatched File</div>
                                        <div  style={{display:'flex', flexDirection:'column'}}>
                                        {
                                         filelist.map((item,index)=>{
                                            return(
                                               
                                                    <a rel="noopener noreferrer" href={item} target="_blank" style={{display:'block'}} key={index}>{index+1}_file</a>
                                               
                                            )
                                        })
                                       }
                                       </div>
                                       
                                    </div>
                          

                            </Col>

                            </Row>
                            }

                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper2">
                                            <div className="itemMemo" dangerouslySetInnerHTML={{__html:ticketInfo.content}}>
                                               
                                            </div>
                                        </div> 
                                </Col>
                            </Row>

                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                        <div className="itemWrapper" style={{justifyContent:'flex-start'}}>
                                            { ticketInfo.writer === user.username && ticketInfo.status === 'Received' &&
                                            <div>
                                         
                                             <Link to={`ticketUpdate/${ticketInfo.id}`} className="btn btn-primary mr-3">Modify</Link>
                                           
                                             <button className="btn btn-danger mr-3" onClick={delTicket}>Del</button>
                                          
                                            </div>
                                            }
                                           
                                                <Link to="/ticketList" className="btn btn-info">List</Link>
                                        </div> 
                                </Col>
                            </Row>
                        
                        </Col>
                    </Row>

                    
                    <PageHeader className="site-page-header_detail" title="Processing result" subTitle='We have completed your request as follows.'/>
                    
                        <Row> 
                            <Col lg={ {span:16, offset:1} }  md={{span:23, offset:1}} sm={{span:23, offset:1}} xs={{span:23, offset:1}} >
                                    <Row>
                                      <Col lg={16} md={24} sm={24} xs={24}>
                                                <div className="itemWrapper">
                                                    <div className="itemLabel font_red">Status</div>
                                                    <div className="form-control font_red" style={{flex:'1'}}>{ticketInfo.status}</div>
                                                </div> 
                                        </Col>
                                    </Row>
                                <Row>
                                <Col lg={16} md={24} sm={24} xs={24}>
                                                <div className="itemWrapper">
                                                    <div className="itemLabel">Hours Worked</div>
                                                    <div className="form-control" style={{flex:'1'}}>{ticketInfo.workHr}</div>
                                                </div> 
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col lg={16} md={24} sm={24} xs={24}>
                                                <div className="itemWrapper">
                                                    <div className="itemLabel">Overtime(1.5 Times)</div>
                                                    <div className="form-control" style={{flex:'1'}}>{ticketInfo.overtime}</div>
                                                </div> 
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                    <Col lg={16} md={24} sm={24} xs={24}>
                                                <div className="itemWrapper">
                                                    <div className="itemLabel">Date & Time Completed</div>
                                                    <div className="form-control" style={{flex:'1'}}>{ticketInfo.complete_dt}</div>
                                                </div> 
                                        </Col>
                                    </Row>
                                    {ticketInfo.jobmemo &&
                                    <Row>
                                    <Col lg={16} md={24} sm={24} xs={24}>
                                                <div className="itemWrapper">
                                                    <div className="memoLabel ">Job Memo</div>
                                                    <div className="jobmemo" style={{flex:'1'}} dangerouslySetInnerHTML={{__html:ticketInfo.jobmemo}}></div>
                                                </div> 
                                        </Col>
                                    </Row>
                                    }
                                
                                </Col>
                        </Row>
                    
                    
                        <Row className="mobile_container">
                            
                            <Col  span={23} offset={1}>
                                <div className="itemWrapper">
                                    <div className="itemLabel font_red">Status</div>
                                    <div className="form-control font_red">{ticketInfo.status}</div>
                                </div> 
                            </Col>


                        </Row>
                    
                    <PageHeader className="site-page-header_detail" title="Job Log" subTitle='This is job logs'/>
                    

                    <Row>
                    <Col lg={ {span:16, offset:1} }  md={{span:23, offset:1}} sm={{span:23, offset:1}} xs={{span:23, offset:1}} >
                            { jobList.length > 0 &&
                              <Row>
                                 <Col lg={16} md={24} sm={24} xs={24}>
                                {
                                  jobList.map( (item,index)=>(
                                 
                                    item.writer === user.username ?

                                    <Card title={`${item.writer} ( ${item.createdAt} )`}  key={index} className="mt-3 mb-3" 
                                    extra={<span onClick={()=>deleteLog(item.id)}><DeleteOutlined /></span>}  headStyle={item.level === '9' ? {backgroundColor:'#f1e9e91f'}: {backgroundColor:'white'}}>
                                        <div className="font_16"  dangerouslySetInnerHTML={{__html:item.content}}>
                                          
                                        </div>
                                    </Card>
                                    
                                    :

                                    <Card title={`${item.writer} ( ${item.createdAt} )`}  key={index} className="mt-3 mb-3" headStyle={item.level === '9' ? {backgroundColor:'#f1e9e91f'}:{backgroundColor:'white'}}>
                                        <div className="font_16"  dangerouslySetInnerHTML={{__html:item.content}}>
                                      
                                        </div>
                                   </Card>

                                  

                                  ))
                                  } 
                                  </Col>
                              </Row>  
                            }

                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>

                                <div className="form-group">
                                    <Input.TextArea placeholder="Comment" rows="8" onChange={e=>setLcoment(e.target.value)} ></Input.TextArea>
                                </div>
                                
                                </Col>
                            </Row>

                            <Row>
                            <Col lg={16} md={24} sm={24} xs={24}>
                                    <div className="itemWrapper" style={{justifyContent:'flex-end'}}>
                                          
                                             <button className="btn btn-danger mr-3" onClick={saveJobLog}>Log Save</button>
                                            
                                           
                                        </div> 

                                
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                
                </div>   

                 {/*start mobile  */}

                <div className="mobile_container">
                    
                    <div className="pageTitle"><AppstoreOutlined /> <span className="titleFont">Ticket Detail</span></div>
                      
                      <Row gutter={[0,12]}>
                        
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemLabel">1.Ticket No.</div>
                                <div className="form-control ml-2">{ticketInfo.ticket_id}</div>
                            </div> 
                        </Col>
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemLabel">2.Time received</div>
                                <div className="form-control  ml-2">{ticketInfo.createdAt}</div>
                            </div>
                        </Col>
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemLabel">3.Title</div>
                                <div className="form-control  ml-2">{ticketInfo.title}</div>
                            </div>
                        </Col>
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemLabel">4.Issuer</div>
                                <div className="form-control  ml-2">{ticketInfo.issuer}</div>
                            </div>
                        </Col>
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemLabel">5.Location</div>
                                <div className="form-control  ml-2">
                                    { ticketInfo.location1 }
                                        {
                                        ticketInfo.location2 &&

                                        <span>/ {ticketInfo.location2}</span>

                                        }
                                
                                </div>
                            </div>
                        </Col>
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemLabel">6.Issue Type</div>
                                <div className="form-control  ml-2">{ticketInfo.issuetype}</div>
                            </div>
                        </Col>
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemLabel">7.Service Type</div>
                                <div className="form-control  ml-2">{ticketInfo.servicetype}</div>
                            </div>
                        </Col>
                        {filelist.length >0 &&
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemLabel">8.Attatched File</div>
                                <div  style={{display:'flex', flexDirection:'column'}}>
                                        {
                                         filelist.map((item,index)=>{
                                            return(
                                               
                                                    <a rel="noopener noreferrer" href={item} target="_blank" style={{display:'block'}} key={index}>{index+1}_file</a>
                                               
                                            )
                                        })
                                       }
                                </div>
                            </div>
                        </Col>
                        }
                        {ticketInfo.content > 0 &&
                        <Col span={24} offset={1}>
                            <div className="itemWrapper">
                                <div className="itemMemo" dangerouslySetInnerHTML={{__html:ticketInfo.content}}>
                                               
                                </div>
                            </div> 
                        </Col>
                        }
                        { ticketInfo.writer === user.username && ticketInfo.status === 'Received' &&
                        <>
                        <Col Col span={24} offset={1}>
                             <Link to={`ticketUpdate/${ticketInfo.id}`} className="btn btn-primary btn-block">Modify</Link>
                        </Col>
                         <Col Col span={24} offset={1}>

                         <button className="btn btn-danger btn-block" onClick={delTicket}>Del</button>

                         </Col>
                        </>
                        }
                        <Col Col span={24} offset={1}>

                        <Link to="/ticketList" className="btn btn-info btn-block">List</Link>

                           
                        </Col>


                       

                      </Row>

                     
                      <div className="pageTitle"><AppstoreOutlined /> <span className="titleFont">Ticket Processing</span></div>
                      
                      <Row gutter={[0,12]}>
                            <Col span={24} offset={1}>
                                <div className="itemWrapper">
                                    <div className="itemLabel">1.Status</div>
                                    <div className="form-control font_red">{ticketInfo.status}</div>
                                </div>
                            </Col>
                            <Col span={24} offset={1}>
                                <div className="itemWrapper">
                                    <div className="itemLabel">2.Hours Worked</div>
                                    <div className="form-control">{ticketInfo.workHr}</div>
                                </div>
                            </Col>
                            <Col span={24} offset={1}>
                                <div className="itemWrapper">
                                    <div className="itemLabel">3.Overtime(1.5 Times)</div>
                                    <div className="form-control">{ticketInfo.overtime}</div>
                                </div>
                            </Col>
                            <Col span={24} offset={1}>
                                <div className="itemWrapper">
                                    <div className="itemLabel">4.Date & Time Completed</div>
                                    <div className="form-control">{ticketInfo.complete_dt}</div>
                                </div>
                            </Col>
                            { ticketInfo.jobmemo &&
                                <Col span={24} offset={1}>
                                <div className="itemWrapper">
                                    <div className="itemLabel">5.Job Memo</div>
                                    <div className="jobmemo"  dangerouslySetInnerHTML={{__html:ticketInfo.jobmemo}}></div>
                                </div>
                                </Col>
                            }

                      </Row>

                     
                      <div className="pageTitle"><AppstoreOutlined /> <span className="titleFont">Job Log</span></div>
                      
                      <Row gutter={[0,12]}>
                      <Col span={24} offset={1}>

                      { jobList.length > 0 &&
                       
                                jobList.map( (item,index)=>(
                                 
                                 item.writer === user.username ?

                                 <Card title={`${item.writer} ( ${item.createdAt} )`}  key={index} className="mt-3 mb-3" 
                                 extra={<span onClick={()=>deleteLog(item.id)}><DeleteOutlined /></span>}  headStyle={item.level === '9' ? {backgroundColor:'#f1e9e91f'}: {backgroundColor:'white'}}>
                                     <div className="font_16"  dangerouslySetInnerHTML={{__html:item.content}}>
                                       
                                     </div>
                                 </Card>
                                 
                                 :

                                 <Card title={`${item.writer} ( ${item.createdAt} )`}  key={index} className="mt-3 mb-3" headStyle={item.level === '9' ? {backgroundColor:'#f1e9e91f'}:{backgroundColor:'white'}}>
                                     <div className="font_16"  dangerouslySetInnerHTML={{__html:item.content}}>
                                   
                                     </div>
                                </Card>

                               

                               ))

                      
                      }
                       </Col>

                       <Col span={24} offset={1}>

                            <div className="form-group" style={{width:'90%'}}>
                            <Input.TextArea placeholder="Comment" rows="8" onChange={e=>setLcoment(e.target.value)} ></Input.TextArea>
                            </div>
                       
                       
                       </Col>

                       <Col span={24} offset={1}>
                       
                            <div className="itemWrapper" style={{display:'flex',justifyContent:'flex-end'}}>
                                          
                                <button className="btn btn-danger mr-3" onClick={saveJobLog}>Log Save</button>
                                                 
                            </div> 
                       
                       
                       </Col>



                      </Row>




                </div>

                {/* end mobile */}
                    
                   
            
            
                </Content>
            </div>
            
        
        </AdminLayout>
        
        
    )
}

export default TicketDetail