import React from 'react';
import axios from 'axios'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


const option={

  
    buttonList: [
        ['undo', 'redo'],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        '/', // Line break
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
        ['fullScreen', 'showBlocks', 'codeView']
        /**['preview', 'print'], */
        /** ['save', 'template'] */
    ]

}

const sEditor = ({getData,Contents, height})=> {

const edit_height = height ? height :'400px'

const handleChange=(content)=>{

        //console.log(content)

        getData(content)

    }



const handleCut=(e, clipboardData)=>{
    
    //console.log("clipboardData",clipboardData)
}


const handlePaste = (e, cleanData, maxCharCount)=>{
	//console.log(e, cleanData, maxCharCount)
}



const handleImageUpload =async (targetImgElement, index, state, imageInfo, remainingFilesCount)=>{

 
        console.log('handleImageUpload')
        console.log(imageInfo)


        const pattern = new RegExp("data:image")

        const src = imageInfo ? imageInfo.src : '';


         if(pattern.test(src)){

            // const formData = new FormData();

            // console.log('name',imageInfo.name);
            // console.log('image',imageInfo.src);
  
            // formData.append('image', imageInfo.src);
            // formData.append('name', imageInfo.name);
            // formData.append('size', imageInfo.size);

            const formData={image:imageInfo.src, name:imageInfo.name}
    
            const res = await apiPostNewsImage(formData); 

            if(res){

                targetImgElement.src = res

            }
    
           

        }
       

       
    }

  
    return (
      
        <SunEditor setOptions={option} width="100%" height={edit_height} setContents={Contents} onChange={handleChange} onImageUpload={handleImageUpload} />
      
  );
};

async function apiPostNewsImage (formData){
           
    const {data} = await axios.post('/edit_upload',formData,{
        headers: {
        'Content-Type': 'application/json'
        }
    })

    console.log(data.url)
  
  
    return data.url
  }

export default sEditor;